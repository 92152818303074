import React from "react";
import { Sidebar, SubMenu, Menu, MenuItem } from 'react-pro-sidebar';
import AssignmentIcon from '@mui/icons-material/Assignment'
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import GridOnIcon from '@mui/icons-material/GridOn';
import ChecklistIcon from '@mui/icons-material/Checklist';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';


const Favorites = () => {
   
  return (
        
        <div className="card w-[40rem] h-[42rem]  justify-between mt-5 profile-card">
          <h1 className="fav-title">Favorites</h1> 
           <div className="card-container"> 
           <button className="sub-card "> 
            <div className="icon"> <AssignmentIcon className="styled-icon" /> </div> 
            <div className="icon-right"> <MoreHorizIcon className="styled-icon" /> </div>
            <div className="sub-card-title"> Sample Survey </div>
           </button>

           <button className="sub-card "> 
           <div className="icon"> <ConfirmationNumberIcon className="styled-icon" /> </div>
           <div className="icon-right"> <MoreHorizIcon className="styled-icon" /> </div>
           <div className="sub-card-title"> Sample Ticket </div>
           </button>

           <button className="sub-card "> 
           <div className="icon"> <GridOnIcon className="styled-icon" /> </div>
           <div className="icon-right"> <MoreHorizIcon className="styled-icon" /> </div>
           <div className="sub-card-title"> Sample Data Entry </div>
           </button>

           <button className="sub-card  ">
           <div className="icon"> <ChecklistIcon className="styled-icon" /> </div> 
           <div className="icon-right"> <MoreHorizIcon className="styled-icon" /> </div>
           <div className="sub-card-title"> Sample Audit </div>
           </button>

           <button className="sub-card ">
           <div className="icon"> <AssignmentIcon className="styled-icon" /> </div>
           <div className="icon-right"> <MoreHorizIcon className="styled-icon" /> </div>
           <div className="sub-card-title"> Sample Survey </div>
           </button>

           <button className="sub-card ">
           <div className="icon"> <ConfirmationNumberIcon className="styled-icon" /> </div> 
           <div className="icon-right"> <MoreHorizIcon className="styled-icon" /> </div>
           <div className="sub-card-title"> Sample Ticket </div>
           </button>

           <button className="sub-card "> 
           <div className="icon"> <GridOnIcon className="styled-icon" /> </div>
           <div className="icon-right"> <MoreHorizIcon className="styled-icon" /> </div>
           <div className="sub-card-title"> Sample Data Entry </div>
           </button>

           <button className="sub-card  ">
           <div className="icon"> <ChecklistIcon className="styled-icon" /> </div>
           <div className="icon-right"> <MoreHorizIcon className="styled-icon" /> </div>
           <div className="sub-card-title"> Sample Audit </div>
           </button>
 
           </div>

        </div>
      );
}
export default Favorites;