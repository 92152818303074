
import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const Profile = () => {
  
  const navigate = useNavigate(); 

  // Function to handle redirection when Archived Forms button is clicked
  const handleArchivedFormsClick = () => {
    navigate('/archived'); // Redirect to the Archived Forms page
  };

  return (
    <div className="card w-[40rem] h-[40rem]  justify-between mt-5 profile-card">
      <h1 className="font-bold mb-4 text-gray-900 dark:text-white text-center">Profile</h1>
      <div className="mb-6 flex justify-center">
        <div className="rounded-full bg-white inline-block">
          <img
            src="https://img.icons8.com/?size=80&id=ywULFSPkh4kI&format=png"
            alt="User"
            className="rounded-full mx-auto border-4 border-white"
          />
        </div>
      </div>
      <div className="profile-details mb-4 text-white">
        <p className="popup-placeholder flex justify-between">
          <label>User ID:</label> <span className="value">94702740</span>
        </p>

        <div className="popup-placeholder-names flex flex-col">
          <p className="popup-placeholder flex justify-between">
            First Name <span>Steven</span>
          </p>
          <hr className="w-[96%] mx-auto border-gray-900" />
          <p className="popup-placeholder flex justify-between">
            Last Name <span>Bautista</span>
          </p>
        </div>

        <button
          className="popup-placeholder flex justify-between"
          onClick={handleArchivedFormsClick} 
        >
          <label>Archived Forms:</label> <span className="value">6
            <button className="ml-2 text-black-300 relative">
              &gt; {/* Using > symbol */}
            </button>
          </span>
        </button>

        <div className="mb-4 flex flex-col space-y-2 w-full">
          <button className="popup-placeholder-btn font-bold">Reset Password</button>
          <button className="popup-placeholder-btn font-bold">Log Out</button>
        </div>
      </div>
    </div>
  );
};

export default Profile;