import React from 'react';
import rubylogImage from '../assets/ruby_logo.svg'

export default function MobilePage() {

  const handleAppStoreClick = () => {
    window.location.href = 'https://apps.apple.com'; // Replace with the actual App Store link
  };

  const handlePlayStoreClick = () => {
    window.location.href = 'https://play.google.com/store'; // Replace with the actual Play Store link
  };
  
  return (
    <div className="mobile-container">
      <img src={rubylogImage} alt="Rubbylog Hexagon" className="hexagon-image" />
      {/* <div className="hexagon"></div> */}
      <button className="mobile-button" onClick={handleAppStoreClick} >Open in App Store</button>
      <button className="mobile-button" onClick={handlePlayStoreClick} >Open in Play Store</button>
    </div>
  );
}