import React, { useEffect, useRef, useState} from "react";
import logo from "../assets/ruby_logo.svg";
import landing1 from "../assets/landing1.svg";
import landing2 from "../assets/landing2.svg";
import landing3 from "../assets/landing3.svg";
import landing4 from "../assets/landing4.svg";
import { NavLink, useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
import { useMutation, useLazyQuery } from "@apollo/client";

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { CREATE_USER, CHECK_USERNAME_AVAILABILITY } from "../GraphQL/NewUsers/Mutations";
import { CREATE_WORKSPACE } from "../GraphQL/NewMethods/Mutation";

const Landing = () => {
  const formRef = useRef(null);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [username, setUsername] = useState("");
  const [isUsernameAvailable, setIsUsernameAvailable] = useState(true);
  const [createUser] = useMutation(CREATE_USER);
  const [createWorkspace] = useMutation(CREATE_WORKSPACE);
  const [checkUsernameAvailability] = useLazyQuery(CHECK_USERNAME_AVAILABILITY);
  const [usernameError, setUsernameError] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });

  // Redirect to dashboard page if user is already logged in
  useEffect(() => {
    if (auth.currentUser) {
      navigate('/', { replace: true });
    }
  }, [auth.currentUser]);

  if (auth && auth.currentUser) {
    return <p>You are logged in.</p>;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateUsername = (name, username) => {
    const hasNumber = /\d/.test(username);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(username);
    const hasConsecutiveUnderscores = /__/.test(username);
    const isLengthValid = username.length >= 5 && username.length <= 16;

    if (username.length < 5) {
      return "At least 5 characters or mix of numbers & characters from 0 - 9";
    } else if (hasConsecutiveUnderscores) {
      return "Username cannot contain consecutive underscores __";
    } else if (hasSpecialChar) {
      return "Username cannot contain special characters, except _";
    } else if (!hasNumber) {
      return "Username must contain at least one number.";
    } else if (!isLengthValid) {
      return "Username must be between 5 and 16 characters long";
    } else {
      return "";
    }
  };

  const handleUsernameChange = async (e) => {
    const newUsername = e.target.value;
    setUsername(newUsername);

    const validationError = validateUsername(formData.name, newUsername);
    setUsernameError(validationError);

    if (!validationError && newUsername) {
      const response = await checkUsernameAvailability({ variables: { username: newUsername } });
      setIsUsernameAvailable(response.data.checkUsernameAvailability.isAvailable);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("Passwords do not match.");
      return;
    }
    if (!isUsernameAvailable) {
      alert("Username is already taken. Please choose another one.");
      return;
    }
    if (usernameError) {
      alert(usernameError);
      return;
    }

    try {
      const userResponse = await createUser({
        variables: {
          input: {
            name: formData.name,
            email: formData.email,
            username: username,
          },
        },
      });

      const userId = userResponse.data.createUser.id;

      await createWorkspace({
        variables: {
          input: {
            name: formData.name + "'s Workspace",
            leader: {
              id: userId,
              name: formData.name,
              email: formData.email,
            },
            members: null,
          },
        },
      });

      setFormData({
        name: "",
        email: "",
      });

      alert(
        "User and Workspace Created Successfully! You can log in with the credentials mailed when we approve your user."
      );
    } catch (error) {
      console.log(`Error creating user: ${error}`);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    await createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        console.log(user);
        navigate("/login");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        alert(`Error: ${errorMessage}`);
      });
  };

  return (
    <div className="">
      <div className="w-screen h-screen bg-white">
        <div className="flex flex-row justify-between p-3">
          <div className="flex flex-row space-x-3">
            <img
              className="w-[62.76px] h-[62.99px] origin-top-left "
              src={logo}
              alt=""
            />
            <div className=" text-stone-800 text-[40px] font-bold font-['Montserrat']">
              rubylog
            </div>
          </div>
          <div className="flex flex-row space-x-3">
            <a
              className="w-[116px] h-[21px] text-black text-xl font-bold font-['Montserrat'] mt-2"
              href="/login"
            >
              Log In
            </a>
            <div className=" flex items-center justify-center text-center w-[242.57px] h-[39.07px] bg-rose-700 rounded-[52px] shadow">
              <button
                className=" text-white text-xl font-bold font-['Montserrat'] pt-1"
                onClick={() => {
                  formRef.current.scrollIntoView({ behavior: "smooth" });
                }}
              >
                Request Demo
              </button>
            </div>
          </div>
        </div>

        <div className="w-screen  flex flex-col justify-center items-center pt-[100px] space-y-[100px]">
          <div className="w-[1200px]  text-center text-rose-700 text-8xl font-medium font-['Nunito'] leading-[120.68px]">
            Transform Your Organization with Efficient Management &
            Communication
          </div>
          <div className="w-[243px] h-11 bg-rose-700 rounded-[52px] flex justify-center items-center">
            <div className=" text-center text-white text-xl font-bold font-['Montserrat']">
              Learn More
            </div>
          </div>
        </div>
      </div>

      <div className="w-screen h-[691px] bg-rose-700 flex flex-row justify-center p-[130px] space-x-[100px]">
        <div className="h-full space-y-3">
          <div className="w-[519px]  text-white text-[40px] font-medium font-['Nunito'] leading-[43.20px] pt-10">
            Empower teams to manage work orders
          </div>
          <div className="w-[479px] text-white text-base font-medium font-['Nunito'] leading-tight">
            Easily create ticket templates and collect responses during
            scheduled availability hours. Provide users with estimated
            completion time and approval notifications to ensure consistent
            quality.
          </div>
        </div>
        <img
          className="w-[574px] h-[462px] rounded-[38px] shadow"
          src={landing1}
          alt=""
        />
      </div>
      <div className="w-screen h-[591px] bg-white flex flex-row justify-center p-[130px] space-x-[150px]">
        <img
          className="w-[564px] h-[221px] rounded-[38px] "
          src={landing2}
          alt=""
        />
        <div className="h-full space-y-3">
          <div className="w-[519px]  text-black text-[40px] font-medium font-['Nunito'] leading-[43.20px] pt-10">
            From inspection to actionable resolution
          </div>
          <div className="w-[479px] text-black text-base font-medium font-['Nunito'] leading-tight">
            Ensure safety, compliance, and quality with user friendly auditing.
            Audits can be regularly assigned to teams or specific users,
            enabling documented consistency across facilities and teams.
          </div>
        </div>
      </div>
      <div className="w-screen h-[591px] bg-rose-700 flex flex-row justify-center p-[130px] space-x-[100px]">
        <div className="h-full space-y-3">
          <div className="w-[519px]  text-white text-[40px] font-medium font-['Nunito'] leading-[43.20px] pt-10">
            Focus on what adds value
          </div>
          <div className="w-[479px] text-white text-base font-medium font-['Nunito'] leading-tight">
            Tickets and audits are only visible to the teams that need to see
            them so they don’t become clutter for users outside the process.
            Group members can see relevant completion status in real-time.
          </div>
        </div>
        <img
          className="w-[574px] h-[254px] rounded-[38px] "
          src={landing3}
          alt=""
        />
      </div>
      <div className="w-screen h-[351px] bg-white flex flex-row justify-center items-center p-[70px] space-x-[300px]">
        <img
          className="w-[357px] h-[91px] rounded-[38px] "
          src={landing4}
          alt=""
        />
        <div className="h-full space-y-3">
          <div className="w-[519px]  text-black text-[40px] font-medium font-['Nunito'] leading-[43.20px] pt-10">
            An organized workforce of teams
          </div>
          <div className="w-[479px] text-black text-base font-medium font-['Nunito'] leading-tight">
            Centralize team-based projects and processes with groups, enhancing
            organization and simplifying management of distinct teams. Group
            assignment ensures flexibility for teams with shared
            responsibilities.{" "}
          </div>
        </div>
      </div>
      <div
        ref={formRef}
        className="flex  justify-center pt-10 w-screen h-[837px] bg-rose-700"
      >
        <div className="flex card w-[700px] h-[715px] items-center ">
          <div className="w-[491.22px] h-[46.48px] text-center text-black text-4xl font-bold font-['Nunito']">
            Request a Demo
          </div>
          <form>
            <div className="mb-4 mt-3">
              <div className="flex flex-row space-x-1">
                <label
                  htmlFor="name"
                  className="block text-black font-black"
                >
                  Name
                </label>
                <h1>*</h1>
              </div>

              <input
                type="text"
                id="name"
                name="name"
                placeholder="Name"
                onChange={(e) => handleInputChange(e)}
                required
                className="w-[500px] px-3 py-2 border border-gray-300 rounded"
              />
            </div>
            <div className="mb-4">
              <div className="flex flex-row space-x-1">
                <label htmlFor="email" className="block text-black font-black">
                  Email
                </label>
                <h1>*</h1>
              </div>
              <input
                type="email"
                name="email"
                label="Email address"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  handleInputChange(e);
                }}
                required
                placeholder="Email address"
                className="w-[500px] px-3 py-2 border border-gray-300 rounded"
              />
            </div>
            <div className="mb-4">
              <div className="flex flex-row space-x-1">
                <label htmlFor="username" className="block text-black font-black">
                  Username
                </label>
                <h1>*</h1>
              </div>
              <input
                type="text"
                id="username"
                name="username"
                placeholder="Username"
                value={username}
                onChange={handleUsernameChange}
                required
                className="w-[500px] px-3 py-2 border border-gray-300 rounded"
              />
              {usernameError && (
                <p className="text-red-500 text-sm">{usernameError}</p>
              )}
              {!usernameError && !isUsernameAvailable && (
                <p className="text-red-500 text-sm">Username is already taken.</p>
              )}
            </div>
            <div className="mb-4">
              <div className="flex flex-row space-x-1">
                <label htmlFor="password" className="block text-black font-black">
                  Password
                </label>
                <h1>*</h1>
              </div>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="w-[500px] px-3 py-2 border border-gray-300 rounded"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </div>
            <div className="mb-4">
              <div className="flex flex-row space-x-1">
                <label htmlFor="confirmPassword" className="block text-black font-black">
                  Confirm Password
                </label>
                <h1>*</h1>
              </div>
              <input
                type={showConfirmPassword ? "text" : "password"}
                id="confirmPassword"
                name="confirmPassword"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                className="w-[500px] px-3 py-2 border border-gray-300 rounded"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </div>
            <button
              type="submit"
              onClick={(e) => {
                handleFormSubmit(e);
                onSubmit(e);
              }}
              className="bg-rose-700 w-[500px] font-black text-white px-4 py-2 rounded-full"
            >
              Submit
            </button>
            <h2 className="text-black font-semibold pt-3 pb-3">
              Already have an account? <NavLink to="/login">Sign in</NavLink>
            </h2>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Landing;
